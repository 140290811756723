import React, { Component } from 'react'
import moment from "moment";
import {PAGE20} from "../../../config/config";
import {checkGroupPermission} from "../../../services/grouppermission";
import ReactPaginate from 'react-paginate'
import {format} from "date-fns";
import {DateUtil} from "../../../utils/dateUtil";
import { NumberUtil } from '../../../utils/number-util'


export  default class CustomerListRow extends Component{

     constructor (props) {
       super(props)
       this.state = {

       }
       this.getCustomerStatus = this.getCustomerStatus.bind(this)
       this.getCustomerStatusWithSubQuery = this.getCustomerStatusWithSubQuery.bind(this)
     }

    getCustomerStatus(customer) {
       // {customer.car_sell_id !== '0' ? <span className="badge bg-success">ลูกค้า</span> : (customer.customer_find_car_id!=='0')?  <span className="badge bg-warning">หารถ</span> : <span className="badge bg-danger">ผู้สนใจ</span>}
       let car_sell_status = customer.car_sell_status
       let car_sell_id = customer.car_sell_id

       let car_book_id = customer.car_book_id
       let car_book_status = customer.car_book_status

       let customer_find_car_id = customer.customer_find_car_id

       if (car_sell_id !== '0' && car_sell_status === 'show') {
         return <span className="badge bg-success">ลูกค้า</span>
       } else if (car_book_id !== '0' && car_book_status === 'show') {
         return <span className="badge bg-primary">จอง</span>
       } else if (customer_find_car_id !== '0') {
         return <span className="badge bg-warning">หารถ</span>
       } else  if (car_sell_id === '0' && car_sell_status === 'notfound' && car_book_id === '0' && car_book_status === 'notfound') {
         return <span className="badge bg-danger">ผู้สนใจ</span>
       } else {
         return <span className="badge bg-danger">ผู้สนใจ</span>
       }
    }

    getCustomerStatusWithSubQuery(customer) {
        // car_sells: car_sells,
        // car_books: car_books,
        // customer_find_cars: customer_find_cars
       let car_sells = customer.car_sells
       let car_books = customer.car_books
       let customer_find_cars = customer.customer_find_cars


        if (car_sells.length !== 0) {

          return <span className="badge bg-success">ลูกค้าซื้อ</span>
        } else if (car_books.length !== 0) {
          return <span className="badge bg-primary">จอง</span>
        } else if (customer_find_cars.length !== 0) {
          return <span className="badge bg-warning">หารถ</span>
        } else {
          return <span className="badge bg-danger">ผู้สนใจ</span>
        }

    }

    render(){

       let grouppermissions = this.props.grouppermissions
        // let user_type = this.props.user_type

       let customer_all = this.props.customer_all
       let page = this.props.page
       let all_page = Math.ceil(customer_all/PAGE20)

       let data_row = this.props.customer_list.length
       let first_row = customer_all - Number(page-1)*Number(PAGE20)

      // let page_array = Array.from(Array(all_page).keys());
      /*let tab_pages = page_array.map((page,index)=>{
        let page_tmp = page+1
        return (
          <li className="page-item" key={index}>
            <button className="page-link"  onClick={()=>{  this.props.onLoadDataPage(page_tmp) }}>
              &nbsp;{page_tmp}&nbsp;
            </button>
          </li>
        )
      });*/

        let customerList = this.props.customer_list.map((customer, index)=>{
            // let order = index + 1
            let order = first_row - index
            // <td className="text-center">{moment( customer.customer_birth ).locale('th').format('LL')}</td>
            // <td className="text-center">{ customer.customer_age }</td>

            let blacklist_bureau_name = customer.blacklist_bureau_name === null ? '-' : customer.blacklist_bureau_name
            let bureau_tracing_name = customer.bureau_tracing_name === null ? '-' : (customer.bureau_tracing_name === 'มี'? customer.bureau_tracing_name + ' ('+customer.bureau_tracing_count+')' :  customer.bureau_tracing_name)
            let finance_credit_result = customer.finance_credit_result === null ? '-' : customer.finance_credit_result
            let date_oneyear_employment = customer.date_oneyear_employment === null ? '-' : DateUtil.convertLongDateThai(format(new Date(customer.date_oneyear_employment), 'yyyy-MM-dd'))
            // date_oneyear_employment
            // " customer.created_at, " +
            //  " customer.user_created, " +
            let customer_created_at = format(new Date(customer.created_at),'dd-MM-yyyy HH:mm:ss')
            let user_created = customer.user_created

            let customer_find_car = customer.customer_find_cars.length === 0 ? null : customer.customer_find_cars[0]

            let employee_admin_id = customer.employee_admin_id
            let employee_admin_name = this.props.getEmployeeFullNameById(employee_admin_id)
           // console.log(customer_find_car)

            return (
                <tr key={index}>
                    <td className="text-center">{ order}</td>
                    <td className="text-nowrap">
                      วันที่เพิ่มข้อมูล: { moment(customer.customer_add_date).locale('th').format('LL')} <br/>
                      ชื่อ-นามสกุล :  { customer.customer_name } &nbsp;&nbsp; { customer.customer_lastname } <br/>
                      หมายเลขบัตรประจำตัว: { customer.customer_card_id } <br/>
                      ที่อยู่ : { customer.customer_address }

                      { customer.customer_find_cars.length === 0 ? null :
                      <>
                        <hr/>
                        <strong> รถที่ลูกค้าต้องการ: </strong> <br/>
                         - ประเภท : {customer_find_car.car_type_interest_name} <br/>
                         - ยี่ห้อ : {customer_find_car.car_brand_name} <br/>
                         - รุ่น : {customer_find_car.car_model_name} <br/>
                         - ยอดผ่อน : {NumberUtil.addCommasZeroInt(customer_find_car.installment_payment)} <br/>
                      </>
                      }


                    </td>
                    <td className="text-left text-nowrap">
                      โทร(1): { customer.customer_mobile }<br/>
                      โทร(2): { customer.customer_mobile2 }<br/>
                      Line: { customer.customer_line }<br/>
                      FB: { customer.customer_facebook }<br/>
                    </td>
                    <td className="text-left text-nowrap">
                        อาชีพ :  {customer.occupation_name} <br/>
                        ประเภทการจ้าง :  {customer.employment_type} <br/>
                        ช่องทางการรับเงิน :  {customer.salary_channel}  <br/>
                        อายุงาน :  {customer.work_experience} <br/>
                        ประเภทใบรับเงินเดือน  :  {customer.salary_receipt_type} <br/>
                        วันที่อายุงานครบ 1 ปี  :  {date_oneyear_employment}

                    </td>
                    <td className="text-left text-nowrap">
                        ติดเครดิตบูโร : {blacklist_bureau_name} <br/>
                        ติดแท็กซิ่ง : {bureau_tracing_name} <br/>
                        ผลการเช็คเครดิตบูโร : {finance_credit_result}
                    </td>
                    <td className="text-left">
                      เซลล์ผู้ดูแล : { customer.employee_name }
                        {
                            employee_admin_name === '' ? null : <>
                                <br/>
                                จากแอดมิน : {employee_admin_name}
                            </>
                        }

                    </td>
                    <td className="text-center">
                      {this.getCustomerStatusWithSubQuery(customer)}
                      <br/>
                      สร้างเมื่อ : {customer_created_at} <br/>
                      โดย : {user_created}
                    </td>
                    <td className="text-center">

                      {
                        checkGroupPermission(4, grouppermissions).viewed ? (
                          <button className="btn btn-sm btn-primary btn-md-width mr-2 mb-2"
                                  style={styles.button}
                                  onClick={this.props.customerCallbacks.openDetail.bind(null,customer)}
                          >รายละเอียด
                          </button>
                        ) : null
                      }

                        {
                            checkGroupPermission(4, grouppermissions).modified ? (
                                <button className="btn btn-sm btn-warning btn-md-width mr-2 mb-2"
                                        style={styles.button}
                                        onClick={this.props.customerCallbacks.edit.bind(null,customer)}
                                >
                                    <i className="fa fa-edit"/> แก้ไข
                                </button>
                            ) : null
                        }

                        {
                            checkGroupPermission(4, grouppermissions).deleted === 1 &&
                            customer.car_books.length === 0 &&
                            customer.car_sells.length === 0
                              ?
                              (
                                <button className={"btn btn-sm btn-danger btn-md-width mb-2 mr-2"}
                                        onClick={this.props.customerCallbacks.delete.bind(null,customer)}
                                        style={styles.button}
                                >
                                  <i className="fa fa-remove"/> ลบ
                                </button>
                              )
                              : null
                        }

                        {
                            checkGroupPermission(4, grouppermissions).modified === 0
                            && checkGroupPermission(4, grouppermissions).deleted === 0
                                ? <><i className="icon-close"/>  </> : null
                        }

                    </td>
                </tr>
            )
        });

        return (
        <div className="card-block" style={styles.overflowY}>
            <table className="table table-striped table-responsive">
                <thead>
                <tr>
                    <th className="text-center">#</th>
                    <th className="text-center"> รายละเอียด </th>
                    <th className="text-center"> ข้อมูลติดต่อ</th>
                    <th className="text-center"> ข้อมูลการเงิน </th>
                    <th className="text-center"> เช็คเครดิตบูโร </th>
                    <th className="text-center text-nowrap">เซลล์/แอดมิน</th>
                    <th className="text-center">สถานะ</th>
                    <th className="text-center" style={{width: 120}}>จัดการ</th>
                </tr>
                </thead>
                <tbody>
                    {customerList}
                    {
                      this.props.customer_list.length === 0 ?
                        <tr><td colSpan={10} className="text-danger text-center">ไม่พบข้อมูล</td></tr> : null
                    }
                </tbody>
            </table>

          {/*<ul className= { this.props.is_search ? "hidden" : "pagination d-flex flex-wrap pagination-sm" }>
            {tab_pages}
          </ul>*/}

          {
            this.props.is_search === true || data_row === 0 || all_page === 1 ? null :
              <nav aria-label="Page navigation comments" className="mt-4">
                <ReactPaginate
                  previousLabel="previous"
                  nextLabel="next"
                  breakLabel="..."
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  pageCount={all_page}
                  pageRangeDisplayed={5}
                  marginPagesDisplayed={4}
                  onPageChange={this.props.handlePageChange}
                  containerClassName="pagination justify-content-center"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  activeClassName="active"
                  // eslint-disable-next-line no-unused-vars
                  hrefBuilder={(page, pageCount, selected) =>
                    page >= 1 && page <= pageCount ? `/page/${page}` : '#'
                  }
                  hrefAllControls
                  forcePage={page-1}
                  onClick={(clickEvent) => {

                    // Return false to prevent standard page change,
                    // return false; // --> Will do nothing.
                    // return a number to choose the next page,
                    // return 4; --> Will go to page 5 (index 4)
                    // return nothing (undefined) to let standard behavior take place.
                  }}
                />
              </nav>
          }

            {/*<ul className="pagination">
                <li className="page-item"><a className="page-link" href="#">Prev</a></li>
                <li className="page-item active">
                    <a className="page-link" href="#">1</a>
                </li>
                <li className="page-item"><a className="page-link" href="#">2</a></li>
                <li className="page-item"><a className="page-link" href="#">3</a></li>
                <li className="page-item"><a className="page-link" href="#">4</a></li>
                <li className="page-item"><a className="page-link" href="#">Next</a></li>
            </ul>*/}
        </div>
        )
    }
}

const styles = {
    button: {
        borderRadius:5,
        height: 36,
        width: 100
    },
    overflowY: {
        overflow: 'scroll',
    }
}
